import React, { useState, useEffect, useCallback } from 'react';
import { Lightbox, ImageSlide, isImageSlide } from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export function ImageGallery(props) {
	const [loading, setLoading] = useState(true);
	const [galleryImages] = useState([]);
	const [aspectRatio] = useState(props.aspectRatio ? props.aspectRatio : "3 / 4");

	const search = useCallback(() => {
		if('images' in props && props.images !== undefined ) {
			for(var pos = 0; pos < props.images.length; pos++ ) {
				var image = props.images[pos];
				var credit = '';
				if('credit' in image ) {
					if('credit_url' in image && image['credit_url'] !== '' ) {
						credit = <a href={image.credit_url} target="_blank" rel="noopener noreferrer nofollow">{image.credit}</a>;
					} else {
						credit = <span>{image.credit}</span>;
					}
				}
				var description = '';
				if('caption' in image && 'credit' in image ) {
					description = <p>{image.caption}<br/>Credit: {credit}</p>
				} else if('caption' in image ) {
					description = <p>{image.caption}</p>
				} else if('credit' in image ) {
					description = <p>Credit: {credit}</p>
				}

				var original = 'https://s3-eu-west-1.amazonaws.com/sinatraology/' + props.type + '/' + image.filename;
				var thumb = 'https://s3-eu-west-1.amazonaws.com/sinatraology-thumbs/' + props.type + '/' + image.filename;

				galleryImages.push({
					'src': original,
					'description': description,
					'thumbnail': thumb,
				});
			}
			setLoading(false);
		}
	}, [galleryImages, props]);

	useEffect(() => { if(loading ) { search() } }, [loading, search])

	if(galleryImages.length > 0) {
		return (
			<Lightbox
				slides={galleryImages}
				plugins={[Inline, Captions, Fullscreen, Thumbnails, Zoom]}
				inline={{
					style: {
						width: "100%",
						maxWidth: "900px",
						aspectRatio: aspectRatio
					}
				}}
				render={{
					thumbnail: ({ slide, rect, render, imageFit }) =>
						isImageSlide(slide) && slide.thumbnail ? (
							<ImageSlide slide={{ src: slide.thumbnail }} rect={rect} render={render} imageFit={imageFit} />
						) : undefined,
				}}
			/>
		);
	}
	return <i>No images available</i>;
}