import React from 'react';
import { PAGE_TITLE_BASE } from './../constants.js';

export function Plan2024(props) {
	document.title = '2024 | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<div style={{textAlign:'left', marginLeft: '100px', marginRight: '100px'}}>
				<h1>
					2024
				</h1>
				<div>
					<h2>2023 in-review</h2>
					<p>2023 saw a re-design of Sinatraology, both visually and behind the scenes. Highlights include:</p>
					<ul>
						<li>New website design</li>
						<li>Improved metadata structure for releases and recordings</li>
						<li>New variant system to allow documentation of releases with non-musical differences, such as "without intro" or promotional excerpts.</li>
						<li>New database of sheet music, with over 700 entries so far</li>
						<li>New database of publications, with over 900 entries so far</li>
						<li>New database of merchandise, with over 400 entries so far</li>
						<li>New database of advertisements, with over 400 entries so far</li>
					</ul>
				</div>
				<div>
					<h2>2024 plan</h2>
					<p>The main goal of 2024 is to cross-reference recording information with the many discographies in the Sinatraology Library, including:</p>
					<ul>
						<li>Songs By Sinatra: 1939-70 (Brian Hainsworth, early 1970s)</li>
						<li>The Sinatrafile: Part One (John Ridgway, 1977)</li>
						<li>The Sinatrafile: Part Two (John Ridgway, 1978)</li>
						<li>The Sinatrafile: Part Three (John Ridgway, 1980)</li>
						<li>Sinatra: The Man and His Music - The Recording Artistry of Francis Albert Sinatra - 1939-1992 (Ed O'Brien and Scott P. Sayers, Jr., 1992)</li>
						<li>Frank Sinatra: A Complete Recording History of Techniques, Songs, Composers, Lyricists, Arrangers, Sessions and First-Issue Albums, 1939-1984 (Richard W. Ackelson, 1992)</li>
						<li>Put Your Dreams Away: A Frank Sinatra Discography (Luiz Carlos Do Nascimento Silva, 2000)</li>
						<li>The Sinatra Legacy (Giuseppe Marcucci, Where or When 3.5 2006)</li>
						<li>Frank Sinatra Sessionography (Sinatra Family, 2021)</li>
					</ul>
					<p>I also plan to complete the digitalisation of the Sinatraology Library, and document it fully in Sinatraology.</p>
				</div>
				<div>
					<h2>2024 plan</h2>
					<p>The main goal of 2024 is to cross-reference recording information with the many discographies in the Sinatraology Library, including:</p>
					<ul>
						<li>The Frank Sinatra Discography (John Deacon, 1961)</li>
						<li>Songs By Sinatra: 1939-70 (Brian Hainsworth, early 1970s)</li>
						<li>The Sinatrafile: Part One (John Ridgway, 1977)</li>
						<li>The Sinatrafile: Part Two (John Ridgway, 1978)</li>
						<li>The Sinatrafile: Part Three (John Ridgway, 1980)</li>
						<li>Sinatra: An Exhaustive Treatise (Albert L. Lonstein, 1983)</li>
						<li>Sinatra: The Man and His Music - The Recording Artistry of Francis Albert Sinatra - 1939-1992 (Ed O'Brien and Scott P. Sayers, Jr., 1992)</li>
						<li>Frank Sinatra: A Complete Recording History of Techniques, Songs, Composers, Lyricists, Arrangers, Sessions and First-Issue Albums, 1939-1984 (Richard W. Ackelson, 1992)</li>
						<li>Sinatra Tracked Down (C. A. Phasey, 1995)</li>
						<li>Frank Sinatra: An American Legend (1915-1998) (Nancy Sinatra, 1998)</li>
						<li>Put Your Dreams Away: A Frank Sinatra Discography (Luiz Carlos Do Nascimento Silva, 2000)</li>
						<li>The Sinatra Legacy (Giuseppe Marcucci, Where or When 3.5 2006)</li>
						<li>Frank Sinatra Sessionography (Sinatra Family, 2021)</li>
					</ul>
					<p>I also plan to complete the digitalisation of the Sinatraology Library, and document it fully in Sinatraology.</p>
				</div>
				<div>
					<h2>On-going tasks</h2>
					<p>Tasks always on the go:</p>
					<ul>
						<li>Release pages: Each of the 70,000 is manually reviewed to link to recordings, albums, labels and ensure images are good. A long, on-going task.</li>
						<li>eBay listings: Review eBay regularly to add information about rare releases, books, merchandise and more to the Sinatraology database</li>
						<li>Amazon listings: Add publication and film release information based on Amazon</li>
						<li>Discogs update: Once a year I sync with the Discogs API to get new and updated release information</li>
						<li>Spotify update: Once a year I sync with the Spotify API to get new streaming release information</li>
					</ul>
					<p>Historically, I'd also sync up with Amazon MP3 Store data every year. This was a manual process that became increasingly harder with Amazon's redesigns, and given that in 2023 Amazon removed most of the non-official Sinatra releases, it seems no longer worthwhile. Instead we are exploring YouTube Music as a datasource for this type of digital release.</p>
				</div>
				<div>
					<h2>Future tasks</h2>
					<p>There are a number of tasks aimed for the future, but won't likely occur this year:</p>
					<ul>
						<li>Sinatra on soundtrack database</li>
						<li>Revival of the newspaper archive project beyond 1942</li>
						<li>Generation of statistics and visulisations for recordings and releases</li>
						<li>Tools to help ensure accuracy of recording and release links</li>
						<li>Revival of the photo database</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
