import React from 'react';
import {SheetMusicBrowseCommon} from './SheetMusicBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function SheetMusicBrowse(props) {
	document.title = 'Sheet Music | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Sheet Music
				</Header>
			</Segment>
			<SheetMusicBrowseCommon urlParams={true}/>
		</div>
	);
}