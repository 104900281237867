import React from 'react';
import {SongBrowseCommon} from './SongBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function SongBrowse(props) {
	document.title = 'Songs | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Songs
				</Header>
			</Segment>
			<SongBrowseCommon urlParams={true}/>
		</div>
	);
}