import React from 'react';
import { PAGE_TITLE_BASE } from './../constants.js';

export function ComingSoon(props) {
	document.title = 'Coming Soon | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<div>
				<h1 style={{textAlign:'center'}}>
					Coming back soon - this page has yet to be fully re-developed for the new-look Sinatraology.
				</h1>
				<div className="getty embed image" style={{marginLeft:'25%',backgroundColor:'#fff',display:'inline-block',fontFamily:'Roboto,sans-serif',color:'#a7a7a7',fontSize:'11px',width:'100%',maxWidth:'594px'}}>
					<div style={{overflow:'hidden',position:'relative',height:0,padding:'67.00337% 0 0 0',width:'100%'}}>
						<iframe src="//embed.gettyimages.com/embed/182974786?et=_7SQ3G5rRfNWUbMnIGwIag&tld=co.uk&sig=VP-__Aqhgi5dL8sYRMOggoqwGXx7aFX4bGPC1m9XRls=&caption=false&ver=1" scrolling="no" frameBorder="0" width="594" height="398" title="500" style={{display:'inline-block',position:'absolute',top:0,left:0,width:'100%',height:'100%',margin:0}}></iframe>
					</div>
					<div style={{padding:0,margin:0,textAlign:'left'}}>
						<a href="http://www.gettyimages.co.uk/detail/182974786" target="_blank" rel="noopener noreferrer" style={{color:'#a7a7a7',textDecoration:'none',fontWeight:'normal !important',border:'none',display:'inline-block'}}>Embed from Getty Images</a>
					</div>
				</div>
			</div>
		</div>
	);
}
