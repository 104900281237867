import React from 'react';
import { PAGE_TITLE_BASE } from './../constants.js';

export function InternalServerError (props) {
	 document.title = 'Error | ' + PAGE_TITLE_BASE;

	 return (
		<div>
		<div>
		 <h1 style={{textAlign:'center'}}>
			Whoops! An error occurred.
		 </h1>
		 <div className="getty embed image" style={{marginLeft:'25%',backgroundColor:'#fff',display:'inline-block',fontFamily:'Roboto,sans-serif',
		 color:'#a7a7a7',fontSize:'11px',width:'100%',maxWidth:'594px'}}>
		 <div style={{overflow:'hidden',position:'relative',height:0,padding:'67.00337% 0 0 0',width:'100%'}}>
		 <iframe src="//embed.gettyimages.com/embed/496559084?et=NCRm8YKgQkFsvmniRz_WQQ&tld=co.uk&sig=rR9HjDVXh1K9xb0L9UkaX8YT0fG3JLC30KASwIOuYEc=&caption=false&ver=1" scrolling="no" frameBorder="0" width="594" height="398" title="500" 
		 style={{display:'inline-block',position:'absolute',top:0,left:0,width:'100%',height:'100%',margin:0}}></iframe>
		 </div>
		 <div style={{padding:0,margin:0,textAlign:'left'}}>
		 <a href="http://www.gettyimages.co.uk/detail/496559084" target="_blank" rel="noopener noreferrer" 
		 style={{color:'#a7a7a7',textDecoration:'none',fontWeight:'normal !important',border:'none',
		 display:'inline-block'}}>Embed from Getty Images</a></div>

		 </div>		 
		 </div>
	 </div>
  );
}
