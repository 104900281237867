import React, { useState } from 'react';
import { SoundtrackBrowseWithVariant } from './../browse/SoundtrackBrowseWithVariant';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function RecordingSoundtrackUsage(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='film' />
							Soundtrack
						</Header>
					</Divider>
					<SoundtrackBrowseWithVariant 
						take={props.take}
						variants={props.variants}
						variant={props.variant}
						urlParams={false}
						relatedRendered={(val) => setChildRendering(val)}
						slim={true}
					/>
				</>
			}
		</>
	);
}