import 'react-app-polyfill/stable';
import 'whatwg-fetch';

import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

import './index.css';

import { Header } from './components/layouts/Header.js';
import { Footer } from './components/layouts/Footer.js';
import { Home } from './components/Home.js';

import { Recording } from './components/views/Recording';
import { RecordingBrowse } from './components/browse/RecordingBrowse';
import { Release } from './components/views/Release';
import { ReleaseBrowse } from './components/browse/ReleaseBrowse';

import { MerchandiseBrowse } from './components/browse/MerchandiseBrowse';
import { SheetMusicBrowse } from './components/browse/SheetMusicBrowse';
import { SongBrowse } from './components/browse/SongBrowse';
import { PersonBrowse } from './components/browse/PersonBrowse';
import { OrganisationBrowse } from './components/browse/OrganisationBrowse';
import { PublicationBrowse } from './components/browse/PublicationBrowse';
import { AdvertBrowse } from './components/browse/AdvertBrowse';
import { YearByYear } from './components/browse/YearByYear';
import { SoundtrackBrowse } from './components/browse/SoundtrackBrowse';

import { ComingSoon } from './components/ComingSoon';
import { InternalServerError } from './components/InternalServerError';
import { PageNotFound } from './components/PageNotFound';
import { Plan2024 } from './components/Plan2024';
import { InformationPlease } from './components/InformationPlease';

				// <Route path="/person/browse" component={PersonBrowse}/>
				// <Redirect from="/person/" to="/person/browse"/>

				// <Route path="/event/browse" to="year/1935"/>
				// <Route path="/event/view/:eventID" component={Event}/>
				// <Redirect from="/event/:eventID/" to="/event/view/:eventID"/>
				// <Redirect from="/event/" to="/event/browse"/>
				
				// <Redirect from="/session/browse/" to="/event/browse"/>
				// <Redirect from="/session/view/:eventID/" to="/event/view/:eventID"/>
				// <Redirect from="/session/:eventID/" to="/event/view/:eventID"/>
				// <Redirect from="/session/" to="/event/browse"/>

				// <Redirect from="/concert/browse/" to="/event/browse"/>
				// <Redirect from="/concert/view/:eventID/" to="/event/view/:eventID"/>
				// <Redirect from="/concert/:eventID/" to="/event/view/:eventID"/>
				// <Redirect from="/concert/" to="/event/browse"/>


				
			// <Route path="/recording/browse" component={RecordingBrowse}/>
			// <Redirect from="/recording/view/:id" to="/recording/:id"/>
			// <Route path="/recording/:id" component={Recording}/>
			// <Redirect from="/recording/" to="/recording/browse"/>

			// <Redirect from="/performance/view/:id" to="/recording/:id"/>
			// <Redirect from="/performance/browse" to="/recording/browse"/>
			// <Redirect from="/performance/:id" to="/recording/:id"/>
			// <Redirect from="/performance/" to="/recording/browse"/>




				// <Route path="/changelog" component={HistoryBrowse}/>
				// <Redirect from="/history/browse" to="/changelog"/>
				// <Redirect from="/history/" to="/changelog"/>


				// <Route path="/calendar" component={ComingSoon}/>
				// <Route path="/500" component={InternalServerError}/>

			// <Redirect path="/performance/view/:id" to="/recording/:id"/>
			// <Redirect path="/performance/browse" to="/recording/browse"/>
			// <Redirect path="/performance/:id" to="/recording/:id"/>
			// <Redirect path="/performance/" to="/recording/browse"/>




const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<BrowserRouter>
		<Header/>
		<Routes>		
			<Route path="/recording/" element={<RecordingBrowse/>}/>
			<Route path="/recording/view/:id" element={<Recording/>}/>
			<Route path="/recording/:id" element={<Recording/>}/>

			<Route path="/release/" element={<ReleaseBrowse/>}/>
			<Route path="/release/view/:id" element={<Release/>}/>
			<Route path="/release/:id" element={<Release/>}/>

			<Route path="/merchandise" element={<MerchandiseBrowse/>}/>
			<Route path="/merchandise/:id" element={<MerchandiseBrowse/>}/>

			<Route path="/sheet_music" element={<SheetMusicBrowse/>}/>
			<Route path="/sheet_music/:id" element={<SheetMusicBrowse/>}/>

			<Route path="/song" element={<SongBrowse/>}/>
			<Route path="/song/:id" element={<SongBrowse/>}/>

			<Route path="/person" element={<PersonBrowse/>}/>
			<Route path="/person/:id" element={<PersonBrowse/>}/>

			<Route path="/organisation" element={<OrganisationBrowse/>}/>
			<Route path="/organisation/:id" element={<OrganisationBrowse/>}/>

			<Route path="/calendar" element={<YearByYear/>}/>
			<Route path="/calendar/:id" element={<YearByYear/>}/>
			<Route path="/event" element={<YearByYear/>}/>
			<Route path="/event/:event_id" element={<YearByYear/>}/>
			<Route path="/radio" element={<YearByYear/>}/>
			<Route path="/radio/:radio_id" element={<YearByYear/>}/>
			<Route path="/television" element={<YearByYear/>}/>
			<Route path="/television/:television_id" element={<YearByYear/>}/>
			<Route path="/film" element={<YearByYear/>}/>
			<Route path="/film/:film_id" element={<YearByYear/>}/>
			<Route path="/photo" element={<YearByYear/>}/>
			<Route path="/photo/:photo_id" element={<YearByYear/>}/>
			<Route path="/album" element={<YearByYear/>}/>
			<Route path="/album/:album_id" element={<YearByYear/>}/>

			<Route path="/publication" element={<PublicationBrowse/>}/>
			<Route path="/publication/:id" element={<PublicationBrowse/>}/>

			<Route path="/advert" element={<AdvertBrowse/>}/>
			<Route path="/advert/:id" element={<AdvertBrowse/>}/>

			<Route path="/soundtrack" element={<SoundtrackBrowse/>}/>
			<Route path="/soundtrack/:id" element={<SoundtrackBrowse/>}/>

			<Route path="/organisation/*" element={<ComingSoon/>}/>

			<Route path="/2024" element={<Plan2024/>} /> 
			<Route path="/information_please" element={<InformationPlease/>} /> 
			<Route path="/404" element={<PageNotFound/>} /> 
			<Route path="/500" element={<InternalServerError/>} /> 
			<Route path="*" element={<Home/>} /> 
		</Routes>
		<Footer/>
	</BrowserRouter>
);

				// <Route path="/" component={App}/>

			// <Route path="/404" element={<PageNotFound/>}/>

					// <Route path="/release/browse" component={ReleaseBrowse}/>
					// <Redirect from="/release/view/:id" to="/release/:id"/>
					// <Route path="/release/:id" component={Release}/>
					// <Redirect from="/release/" to="/release/browse"/>


					// <Route path="/events/browse" component={YearByYear}/>
					// <Redirect from="/year/:year" to="/events/browse?mode=year&year=:year"/>
					// <Redirect from="/year/" to="/events/browse"/>
					// <Redirect from="/events/" to="/events/browse"/>

					// <Redirect from="/event/view/:event" to="/events/browse?mode=event&event=:event"/>
					// <Redirect from="/event/:event" to="/events/browse?mode=event&event=:event"/>
					// <Redirect from="/photo/view/:photo" to="/events/browse?mode=photo&photo=:photo"/>
					// <Redirect from="/photo/:photo" to="/events/browse?mode=photo&photo=:photo"/>
					// <Redirect from="/radio/view/:radio" to="/events/browse?mode=radio&radio=:radio"/>
					// <Redirect from="/radio/:radio" to="/events/browse?mode=radio&radio=:radio"/>
					// <Redirect from="/television/view/:television" to="/events/browse?mode=television&television=:television"/>
					// <Redirect from="/television/:television" to="/events/browse?mode=television&television=:television"/>
					// <Redirect from="/film/view/:film" to="/events/browse?mode=film&film=:film"/>
					// <Redirect from="/film/:film" to="/events/browse?mode=film&film=:film"/>
					// <Redirect from="/advert/view/:advert" to="/events/browse?mode=advert&advert=:advert"/>
					// <Redirect from="/advert/:advert" to="/events/browse?mode=advert&advert=:advert"/>

					// <Route path="/merchandise/browse" component={MerchandiseYearByYear}/>
					// <Redirect from="/sheet_music/browse" to="/merchandise/browse?mode=sheet_music"/>
					// <Redirect from="/publication/browse" to="/merchandise/browse?mode=publication"/>
					// <Redirect from="/merchandise_item/browse" to="/merchandise/browse?mode=merchandise"/>
					// <Redirect from="/soundtrack/browse" to="/merchandise/browse?mode=soundtrack"/>
					// <Redirect from="/merchandise/:year" to="/merchandise/browse?mode=year&year=:year"/>
					// <Redirect from="/sheet_music/view/:sheet_music" to="/merchandise/browse?mode=sheet_music&sheet_music=:sheet_music"/>
					// <Redirect from="/sheet_music/:sheet_music" to="/merchandise/browse?mode=sheet_music&sheet_music=:sheet_music"/>
					// <Redirect from="/merchandise_item/view/:merchandise" to="/merchandise/browse?mode=merchandise&merchandise=:merchandise"/>
					// <Redirect from="/merchandise_item/:merchandise" to="/merchandise/browse?mode=merchandise&merchandise=:merchandise"/>
					// <Redirect from="/publication/view/:publication" to="/merchandise/browse?mode=publication&publication=:publication"/>
					// <Redirect from="/publication/:publication" to="/merchandise/browse?mode=publication&publication=:publication"/>
					// <Redirect from="/soundtrack/view/:soundtrack" to="/merchandise/browse?mode=soundtrack&soundtrack=:soundtrack"/>
					// <Redirect from="/soundtrack/:soundtrack" to="/merchandise/browse?mode=soundtrack&soundtrack=:soundtrack"/>
					// <Redirect from="/sheet_music/" to="/merchandise/browse?mode=sheet_music"/>
					// <Redirect from="/publication/" to="/merchandise/browse?mode=publication"/>
					// <Redirect from="/merchandise_item/" to="/merchandise/browse?mode=merchandise"/>
					// <Redirect from="/soundtrack/" to="/merchandise/browse?mode=soundtrack"/>
					// <Redirect from="/merchandise/" to="/merchandise/browse"/>

					// <Route path="/art/browse" component={ArtBrowse}/>
					// <Redirect from="/art/:year" to="/art/browse?mode=year&year=:year"/>
					// <Redirect from="/art_item/view/:art" to="/art/browse?mode=art&art=:art"/>
					// <Redirect from="/art_item/:art" to="/art/browse?mode=art&art=:art"/>
					// <Redirect from="/art/" to="/art/browse"/>

					// <Route path="/song/browse" component={SongBrowse}/>
					// <Redirect from="/song/view/:id" to="/song/:id"/>
					// <Route path="/song/:id" component={Song}/>
					// <Redirect from="/song/" to="/song/browse"/>

					// <Redirect from="/person/view/:id" to="/person/:id"/>
					// <Route path="/person/:id" component={Person}/>

					// <Route path="/" component={Home}/>


				// <Route path="/concert_tour/view/:concertTourID" component={ConcertTourRoute}/>
				// <Redirect from="/concert_tour/:concertTourID" to="/concert_tour/view/:concertTourID"/>
				// <Route path="/television/browse" component={TelevisionBrowseRoute}/>
				// <Route path="/television/view/:televisionID" component={TelevisionRoute}/>
				// <Redirect from="/television/:televisionID" to="/television/view/:televisionID"/>
				// <Redirect from="/television/" to="/television/browse"/>

				// <Route path="/film/browse" component={FilmBrowseRoute}/>
				// <Route path="/film/view/:filmID" component={FilmRoute}/>
				// <Redirect from="/film/:filmID" to="/film/view/:filmID"/>
				// <Redirect from="/film/" to="/film/browse"/>

				// <Route path="/art/browse" component={ArtBrowseRoute}/>
				// <Route path="/art/view/:artID" component={ArtRoute}/>
				// <Redirect from="/art/:artID" to="/art/view/:artID"/>
				// <Redirect from="/art/" to="/art/browse"/>

				// <Route path="/portraiture/browse" component={PortraitureBrowseRoute}/>
				// <Route path="/portraiture/view/:portraitureID" component={PortraitureRoute}/>
				// <Redirect from="/portraiture/:portraitureID" to="/portraiture/view/:portraitureID"/>
				// <Redirect from="/portraiture/" to="/portraiture/browse"/>

				// <Route path="/publication/browse" component={PublicationBrowseRoute}/>
				// <Route path="/publication/view/:publicationID" component={PublicationRoute}/>
				// <Redirect from="/publication/:publicationID" to="/publication/view/:publicationID"/>
				// <Redirect from="/publication/" to="/publication/browse"/>

				// <Route path="/book/browse" to="/book/browse"/>
				// <Route path="/book/view/:publicationID" to="/book/view/:publicationID"/>
				// <Redirect from="/book/:publicationID" to="/book/view/:publicationID"/>
				// <Redirect from="/book/" to="/book/browse"/>

				// <Route path="/merchandise/browse" component={MerchandiseBrowseRoute}/>
				// <Route path="/merchandise/view/:merchandiseID" component={MerchandiseRoute}/>
				// <Redirect from="/merchandise/:merchandiseID" to="/merchandise/view/:merchandiseID"/>
				// <Redirect from="/merchandise/" to="/merchandise/browse"/>

				// <Route path="/commercial/browse" component={CommercialBrowseRoute}/>
				// <Route path="/commercial/view/:commercialID" component={CommercialRoute}/>
				// <Redirect from="/commercial/:commercialID" to="/commercial/view/:commercialID"/>
				// <Redirect from="/commercial/" to="/commercial/browse"/>

				// <Route path="/organisation/browse" component={OrganisationBrowseRoute}/>
				// <Route path="/organisation/view/:organisationID" component={OrganisationRoute}/>
				// <Redirect from="/organisation/:organisationID" to="/organisation/view/:organisationID"/>
				// <Redirect from="/organisation/" to="/organisation/browse"/>
				// <Route path="/label/browse" to="/organisation/browse"/>
				// <Route path="/label/view/:organisationID" to="/organisation/view/:organisationID"/>
				// <Redirect from="/label/:organisationID" to="/organisation/view/:organisationID"/>
				// <Redirect from="/label/" to="/organisation/browse"/>

				// <Route path="/playlists" component={PlaylistsRoute}/>
				
				// <Route path="/article/view/:articleID" component={ArticleRoute}/>
				// <Redirect from="/article/:articleID" to="/article/view/:articleID"/>
				// <Redirect from="/list/view/:articleID" to="/article/view/:articleID"/>
				// <Redirect from="/list/:articleID" to="/article/view/:articleID"/>

				// <Route path="/projects" component={Projects}/>
				// <Route path="/radio/browse" component={RadioBrowse}/>
				// <Redirect from="/radio/" to="/radio/browse"/>
				// <Route path="/radio/view/:radioID" component={Radio}/>
				// <Redirect from="/radio/:radioID" to="/radio/view/:radioID"/>

// // import ReactGA from 'react-ga';

// export function App(props) {
// 	document.title = PAGE_TITLE_BASE;
// 	// ReactGA.initialize('UA-61555928-1');
	
// 	// ReactGA.pageview(window.location.pathname + window.location.search);
