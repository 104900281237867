import React from 'react';
import {MerchandiseBrowseCommon} from './MerchandiseBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function MerchandiseBrowse(props) {
	document.title = 'Merchandise and Memorabilia | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Merchandise and Memorabilia
				</Header>
			</Segment>
			<MerchandiseBrowseCommon urlParams={true}/>
		</div>
	);
}