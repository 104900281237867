import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS, PAGE_TITLE_BASE } from './../../constants.js';
import { SessionPersonnel } from './../subcomponents/SessionPersonnel';
import { References } from './../subcomponents/References';
import { RecordingTakeReleases } from './../subcomponents/RecordingTakeReleases';
import { RecordingSoundtrackUsage } from './../subcomponents/RecordingSoundtrackUsage';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { Dimmer, Loader, Segment, Header, Grid, Table, Divider, Icon, Tab, Message, Button, Modal, Form } from 'semantic-ui-react'

export function Recording (props) {
	const {id} = useParams();
	const [idUpdated, setIdUpdated] = useState(id);
	useEffect(() => { if(idUpdated !== id ) { setIdUpdated(id); setLoading(true) } }, [idUpdated, id])

	const [recording, setRecording] = useState({});
	const [loading, setLoading] = useState(true);

	const [showVariantFilterModal, setShowVariantFilterModal] = useState(false);
	const [variant, setVariant] = useState(0);

	const navigate = useNavigate();

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/recording/${idUpdated}`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				if(json.length === 0 ) {
					navigate('/404');
				}
				document.title = json[0]['page_title'] + ' | ' + PAGE_TITLE_BASE;
				setRecording(json[0]);
				setLoading(false);
			}).catch(function(ex) {
				console.log(ex);
				navigate('/500');
			})
	}, [idUpdated, navigate]);

	useEffect(() => { if(loading ) { search() } }, [loading, search])

	const clearVariantFilter = () => {
		setShowVariantFilterModal(false);
		setVariant(0);
	}
	const runVariantFilters = () => {
		setShowVariantFilterModal(false);
	}

	var discographicalNotes = recording['discographical-notes'];
	if(recording && Object.keys(recording) > 0 ) {
		recording.takes.sort(function(a,b) {
			if(a.position > b.position ) {
				return 1;
			} else if(a.position < b.position ) {
				return -1;
			} else {
				return 0;
			}
		});
	}
	if(recording && recording.sessions ) {
		var session_count = recording.sessions.length;
		recording.sessions.map(function(session) {
			if('discographical-notes' in session.obj ) {
				if(discographicalNotes !== undefined ) {
					discographicalNotes += "<br/><br/>";
				} else {
					discographicalNotes = "<p>";
				}
				if(session_count > 1 ) {
					discographicalNotes += "<b>Session notes: " + (session.obj.date_display) + "</b><br/>";
				} else {
					discographicalNotes += "<b>Session notes</b><br/>";
				}
				discographicalNotes += session.obj['discographical-notes'];
			}
			if(discographicalNotes !== undefined) {
				discographicalNotes += "</p>";
			}
			return discographicalNotes;
		});
	}
	const takes = [];
	if('takes' in recording) {
		recording.takes.sort((a,b) => { return a.position === b.position ? 0 : a.position < b.position ? -1 : 1 });
		recording.takes.map(function(take) {
			var variantFilterText = '';
			if(variant) {
				for(var variantNum in take.variants) {
					if(variant === take.variants[variantNum]._id) {
						variantFilterText = take.variants[variantNum].name;
					}
				}
			}
			var tab = {
				menuItem: take.name,
				render: () => <Tab.Pane>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='info circle' />
							Details
						</Header>
					</Divider>
					<Table definition inverted>
						<Table.Body>
							<Table.Row>
								<Table.HeaderCell width={2}>Name</Table.HeaderCell>
								<Table.Cell>{take.name}</Table.Cell> 
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell width={2}>Type</Table.HeaderCell>
								<Table.Cell>{take.type}</Table.Cell> 
							</Table.Row>
							{'length' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}>Length</Table.HeaderCell>
									<Table.Cell>{take.length}</Table.Cell> 
								</Table.Row>
							}
							{'recordings' in take && 
								<>
									<Table.Row>
										<Table.HeaderCell colSpan={2}>This take consists of multiple recordings</Table.HeaderCell>
									</Table.Row>
									{take.recordings.map(function(take_recording, index){
										return <Table.Row>
											<Table.HeaderCell>&#8627; {index+1}</Table.HeaderCell>
											<Table.Cell>
												{take_recording.id === recording._id &&
													<>{take_recording.name}</>
												}
												{take_recording.id !== recording._id &&
													<Link to={'/recording/' + take_recording.id}>{take_recording.name}</Link>
												}
											</Table.Cell>
										</Table.Row>
									})

									}
								</>
							}
							{'notes' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}>Notes</Table.HeaderCell>
									<Table.Cell><p dangerouslySetInnerHTML={{__html: take.notes}}></p></Table.Cell> 
								</Table.Row>
							}
							{'recording-audio' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}><Icon name='sound' title='Audio recording in-circulation'/></Table.HeaderCell>
									<Table.Cell>Audio recording in-circulation</Table.Cell> 
								</Table.Row>
							}
							{'recording-video' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}><Icon name='video' title='Video recording in-circulation'/></Table.HeaderCell>
									<Table.Cell>Video recording in-circulation</Table.Cell> 
								</Table.Row>
							}
							{'recording-unreleased' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}><Icon name='lock' title='Recording unreleased'/></Table.HeaderCell>
									<Table.Cell>Recording unreleased</Table.Cell> 
								</Table.Row>
							}
							{'recording-no_recording' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}><Icon name='close' title='No known recording'/></Table.HeaderCell>
									<Table.Cell>No known recording</Table.Cell> 
								</Table.Row>
							}
							{'sinatraology_collection' in take &&
								<Table.Row>
									<Table.HeaderCell width={2}><Icon name='check square outline' title='In the Sinatraology Collection'/></Table.HeaderCell>
									<Table.Cell>In the Sinatraology Collection</Table.Cell> 
								</Table.Row>
							}
						</Table.Body>
					</Table>
					{'variants' in take && 
						<>
							<Divider horizontal inverted>
								<Header as='h4' inverted>
									<Icon name='cut' />
									Variants
								</Header>
							</Divider>
							<Table definition inverted>
								<Table.Body>
									<Table.Row>
										<Table.HeaderCell>Name</Table.HeaderCell>
										<Table.HeaderCell>Type</Table.HeaderCell>
										<Table.HeaderCell>Length</Table.HeaderCell>
										<Table.HeaderCell>Notes</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
									{take.variants.map(function(variant){
										let types = [];
										variant.types.map(function(type) {
											types.push(<span key={'type_' + type}>{type}</span>);
											types.push(<br key={'br_' + type}/>);
											return type;
										});
										return <Table.Row key={variant._id}>
											<Table.Cell>{variant.name}</Table.Cell>
											<Table.Cell>{types}</Table.Cell>
											<Table.Cell>{variant.length}</Table.Cell>
											<Table.Cell>
												<p dangerouslySetInnerHTML={{__html: variant.notes}}></p>
												{variant.event_variants && 
													<><b>Incorporates the following:</b>
														<ul>
															{variant.event_variants.map(function(ev) {
																return <li key={ev._id}><Link to={'/event_variant/' + ev._id}>{ev.name}</Link></li>
															})}
														</ul>
													</>
												}
												{variant.radio_variants && 
													<><b>Incorporates the following:</b>
														<ul>
															{variant.radio_variants.map(function(ev) {
																return <li key={ev._id}><Link to={'/radio_variant/' + ev._id}>{ev.name}</Link></li>
															})}
														</ul>
													</>
												}
												{('_type' in variant && variant._type === 'event_variant') && 
													<Link to={'/event/' + variant.event}>Event link</Link>
												}
												{('_type' in variant && variant._type === 'radio_variant') && 
													<Link to={'/radio/' + variant.radio}>Radio link</Link>
												}
												{variant.other_takes && 
													<><b>Incorporates the following:</b>
														<ul>
															{variant.other_takes.map(function(ev) {
																return <li key={ev._id}><Link to={'/recording/' + ev.recording._id + '#take_' + ev._id}>{ev.recording.song.title + ' - ' + ev.recording.session.date_display + ' (' + ev.name + ')'}</Link></li>
															})}
														</ul>
													</>
												}
											</Table.Cell>
											<Table.Cell>
												{'recording-audio' in variant && <Icon name='sound' title='Audio recording in-circulation'/>}
												{'recording-video' in variant && <Icon name='video' title='Video recording in-circulation'/>}
												{'recording-unreleased' in variant && <Icon name='lock' title='Recording unreleased'/>}
												{'sinatraology_collection' in variant && <Icon name='check square outline' title='In the Sinatraology Collection'/>}
											</Table.Cell>
										</Table.Row>
									})}
								</Table.Body>
							</Table>
							<Grid.Row>
								<Grid.Column className='variantFilter'>
									<p>Filter releases and soundtracks based on the variant: </p>
									<Button onClick={() => setShowVariantFilterModal(true)}>
										Variant Filter
									</Button>
									{variantFilterText && <p><i>Variant filter active: {variantFilterText}</i></p>}
								</Grid.Column>
							</Grid.Row>
							<Modal
								dimmer='blurring'
								open={showVariantFilterModal}
								onClose={() => runVariantFilters()}
							>
								<Modal.Header>Variant Filter</Modal.Header>
								<Modal.Content>
									<Form>
										<Form.Group grouped>
											{take.variants.map(function(vari) {
												return <Form.Field
													key={vari._id}
													control='input'
													type='radio'
													name='variantFilter'
													label={vari.name}
													checked={variant === vari._id}
													onChange={(e) => setVariant(vari._id)}
												/>
											})}
										</Form.Group>
									</Form>
								</Modal.Content>
								<Modal.Actions>
									<Button onClick={() => clearVariantFilter()}>
										Reset filter
									</Button>
									<Button positive onClick={() => runVariantFilters()}>
										Filter
									</Button>
								</Modal.Actions>
							</Modal>
						</>
					}
					<RecordingTakeReleases key={'releases_' + take._id + '_varaint_' + variant} take={take._id} variants={take.variants} variant={variant}/>
					<RecordingSoundtrackUsage key={'soundtrack_' + take._id + '_varaint_' + variant} take={take._id} variants={take.variants} variant={variant}/>
				</Tab.Pane>
			};
			takes.push(tab);
			return tab;
		});
	}

	return (
		<Segment inverted style={{minHeight:'500px', margin:'1%'}}>
			<Dimmer active={loading}>
				<Loader size='large'>Loading</Loader>
			</Dimmer>
			<Grid columns={2} stackable>
				{'pending' in recording &&
					<>
						<Grid.Row>
							<Message warning style={{textAlign:'center'}}>
								<Message.Header>This page is marked as pending, which means the discographical accuracy is still being verified and cross-referenced.<br/><br/>Note this page is yet to be reviewed for adaptation to the updated Sinatraology user interface.</Message.Header>
							</Message>
						</Grid.Row>
						<Grid.Row>
							<Message warning style={{textAlign:'center'}}>
								<Message.Header><br/>Release and recording pages are still being re-organised/reviewed for accuracy - not easy with over 70,000 entires.</Message.Header>
							</Message>
						</Grid.Row>
					</>
				}
				{'info_wanted' in recording &&
					<Message negative icon>
						<Message.Header><Icon name='help icon'/> Information Wanted</Message.Header>
						<Message.Content>
							We need more information to determine the recording date for this track.<br/>
							If you have any information, please contact us at <a href="contact:admin@sinatraology.com">admin@sinatraology.com</a>.
						</Message.Content>
					</Message>
				}
				<Grid.Row>
					<Header as='h1' inverted style={{textAlign:'center'}}>{recording['page_title']}</Header>
				</Grid.Row>
				<Grid.Row columns={2} stackable>
					<Grid.Column>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='info circle' />
								Recording Details
							</Header>
						</Divider>
						<Table definition inverted>
							<Table.Body>
								<Table.Row>
									<Table.HeaderCell width={2}>Artist</Table.HeaderCell>
									<Table.Cell>{recording.artist}</Table.Cell> 
								</Table.Row>
								{recording.sessions && recording.sessions.map(function(session, i) {
									return (
										<Fragment key={'session_' + i}>
											{recording.sessions.length > 1 && 
												<Table.Row>
													<Table.HeaderCell colSpan={2}>Session #{i+1} - {session.type}</Table.HeaderCell>
												</Table.Row>
											}
											<Table.Row>
												<Table.HeaderCell width={2}>Date</Table.HeaderCell>
												<Table.Cell><Link to={'/event/' + session.obj._id}>{session.obj.date_display}</Link>{session.obj.date_timing && <><br/>{session.obj.date_timing}</>}</Table.Cell>
											</Table.Row>
											{('obj' in session && 'type' in session.obj) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Type</Table.HeaderCell>
													<Table.Cell>{session.obj.type}</Table.Cell>
												</Table.Row>
											}					
											{('obj' in session && 'film' in session.obj && session.obj.film.length > 0) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Film</Table.HeaderCell>
													<Table.Cell><Link to={'/film/' + session.obj.film[0]._id}>{session.obj.film[0].title}</Link></Table.Cell>
												</Table.Row>
											}									
											{('obj' in session && 'radio' in session.obj && session.obj.radio.length > 0) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Radio</Table.HeaderCell>
													<Table.Cell><Link to={'/radio/' + session.obj.radio[0]._id}>{session.obj.radio[0].name}</Link></Table.Cell>
												</Table.Row>
											}																
											{('obj' in session && 'television' in session.obj && session.obj.television.length > 0) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Television</Table.HeaderCell>
													<Table.Cell><Link to={'/television/' + session.obj.television[0]._id}>{session.obj.television[0].date_display}</Link></Table.Cell>
												</Table.Row>
											}									
											{('obj' in session && 'organisations' in session.obj && session.obj.organisations.length > 0) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Label</Table.HeaderCell>
													<Table.Cell><Link to={'/organisation/' + session.obj.organisations[0]._id}>{session.obj.organisations[0].name}</Link></Table.Cell>
												</Table.Row>
											}
											{('obj' in session && 'location' in session.obj) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Location</Table.HeaderCell>
													<Table.Cell>{session.obj.location}</Table.Cell>
												</Table.Row>
											}
											{('obj' in session && 'session_number' in session.obj) &&
												<Table.Row>
													<Table.HeaderCell width={2}>Session #</Table.HeaderCell>
													<Table.Cell>{session.obj.session_number}</Table.Cell>
												</Table.Row>
											}
									</Fragment>)
								})}
								{recording.sessions && recording.sessions.length > 1 && 
									<Table.Row>
										<Table.HeaderCell colSpan={2}></Table.HeaderCell>
									</Table.Row>
								}
								{'master' in recording &&
									<Table.Row>
										<Table.HeaderCell width={2}>Master #</Table.HeaderCell>
										<Table.Cell>{recording.master}</Table.Cell> 
									</Table.Row>
								}
							</Table.Body>
						</Table>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='male' />
								Musicians
							</Header>
						</Divider>
						<SessionPersonnel people={recording.people} sessions={recording.sessions} session={false}/>
					</Grid.Column>
					<Grid.Column>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='music' />
								Song Details
							</Header>
						</Divider>
						<Table definition inverted>
							<Table.Body>
								{('song' in recording && recording.song[0]) &&
									<>
										<Table.Row>
											<Table.HeaderCell width={2}>Title</Table.HeaderCell>
											<Table.Cell><Link to={'/song/' + recording.song[0]._id}>{recording.song[0].title}</Link></Table.Cell>
										</Table.Row>
										{('medley-songs' in recording.song[0] && recording.song[0]['medley-songs'].length > 0) && 
											<>
												<Table.Row>
													<Table.HeaderCell colSpan="2">
														<b>Medley of</b>
													</Table.HeaderCell>
												</Table.Row>
												{recording.song[0]['medley-songs'].map(function(song, mi) {
													let title = song.title;
													if('parody' in song) {
														title += ' [parody]';
													}
													if('sample' in song) {
														title += ' [sample]';
													}
													return <Table.Row key={'medley_' + mi}>
														<Table.HeaderCell>&#8627; {mi+1}</Table.HeaderCell>
														<Table.Cell><Link to={'/song/' + song.song}>{title}</Link></Table.Cell>
													</Table.Row>;
												})}
											</>
										}
										{('source_str' in recording.song[0] && recording.song[0].source_str !== '') && 
											<Table.Row>
												<Table.HeaderCell width={2}>Source</Table.HeaderCell>
												<Table.Cell><p dangerouslySetInnerHTML={{__html: recording.song[0].source_str}}></p></Table.Cell>
											</Table.Row>
										}
										{'people' in recording.song[0] &&
											<>
												{Object.keys(recording.song[0].people).map(function(role, i) {
													return (
														<Fragment key={'role_'+i}>
															<Table.Row>
																<Table.HeaderCell rowSpan={recording.song[0].people[role].length}>{role}</Table.HeaderCell>
																<Table.Cell><Link to={'/person/' + recording.song[0].people[role][0]._id}>{recording.song[0].people[role][0].name}</Link></Table.Cell>
															</Table.Row>
															{recording.song[0].people[role].map(function(person, i) {
																if(i === 0) { return <Fragment key={'person_' + i}></Fragment>; }
																return <Table.Row key={'person_' + i}>
																	<Table.Cell><Link to={'/person/' + person._id}>{person.name}</Link></Table.Cell>
																</Table.Row>
															})}
														</Fragment>
													)
												})}
											</>
										}
									</>
								}
								{('arrangement' in recording && recording.arrangement[0]) &&
									<>
										{'people' in recording.arrangement[0] &&
											<>
												{Object.keys(recording.arrangement[0].people).map(function(role, i) {
													return (
														<Fragment key={'role_'+i}>
															<Table.Row>
																<Table.HeaderCell rowSpan={recording.arrangement[0].people[role].length}>{role}</Table.HeaderCell>
																<Table.Cell><Link to={'/person/' + recording.arrangement[0].people[role][0]._id}>{recording.arrangement[0].people[role][0].name}</Link></Table.Cell>
															</Table.Row>
															{recording.arrangement[0].people[role].map(function(person, i) {
																if(i === 0) { return <Fragment key={'person_' + i}></Fragment>; }
																return <Table.Row key={'person_' + i}>
																	<Table.Cell><Link to={'/person/' + person._id}>{person.name}</Link></Table.Cell>
																</Table.Row>
															})}
														</Fragment>
													)
												})}
											</>
										}
									</>
								}
							</Table.Body>
						</Table>
						{'notes' in recording &&
							<>
								<Divider horizontal inverted>
									<Header as='h4' inverted>
										<Icon name='sticky note outline' />
										Notes
									</Header>
								</Divider>
								<p dangerouslySetInnerHTML={{__html: recording.notes}}></p>
							</>
						}
						{discographicalNotes &&
							<>
								<Divider horizontal inverted>
									<Header as='h4' inverted>
										<Icon name='book' />
										Discographical Notes
									</Header>
								</Divider>
								<p dangerouslySetInnerHTML={{__html: discographicalNotes}}></p>
							</>
						}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='file outline' />
							Takes
						</Header>
					</Divider>
					<Tab panes={takes} menu={{ inverted: true, attached: true, tabular: true }} />
				</Grid.Row>
				{('references' in recording && recording.references.length > 0) &&
					<Grid.Row>
						<References references={recording.references} acknowledgements={recording.acknowledgements}/>
					</Grid.Row>
				}
			</Grid>
		</Segment>
	);
}

					// {'release-stats' in recording && 
					// 	<>
					// 		<Divider horizontal inverted>
					// 			<Header as='h4' inverted>
					// 				<Icon name='chart bar' />
					// 				Release Stats
					// 			</Header>
					// 		</Divider>
					// 		<Table definition inverted collapsing style={{marginLeft: 'auto', marginRight: 'auto'}}>
					// 			<Table.Body>
					// 				<Table.Row>
					// 					<Table.HeaderCell>Format</Table.HeaderCell>
					// 					<Table.HeaderCell>Official</Table.HeaderCell>
					// 					<Table.HeaderCell>Unauthorised</Table.HeaderCell>
					// 					<Table.HeaderCell>Bootleg</Table.HeaderCell>
					// 				</Table.Row>
					// 				{recording['release-stats'].map(function(format) {
					// 					return <Table.Row key={format}>
					// 						<Table.Cell>{format.name}</Table.Cell>
					// 						<Table.Cell>{format.official}</Table.Cell>
					// 						<Table.Cell>{format.unauthorised}</Table.Cell>
					// 						<Table.Cell>{format.bootleg}</Table.Cell>
					// 					</Table.Row>
					// 				})}
					// 			</Table.Body>
					// 		</Table>
					// 	</>
					// }