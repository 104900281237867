import React, { useState } from 'react';
import { SongBrowseCommon } from './../browse/SongBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function PersonSongs(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='music' />
							Songs
						</Header>
					</Divider>
					<SongBrowseCommon slim={true} person={props.person}
						urlParams={false} relatedRendered={(val) => setChildRendering(val)}/>
				</>
			}
		</>
	);
}