import React, { useState } from 'react';
import { PublicationBrowseCommon } from './../browse/PublicationBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function EventPublications(props) {
	const [childRendering, setChildRendering] = useState(true);
	let event_split = props.event.split('_');
	let event;
	let film;
	let television;
	let concert_tour;
	if(event_split[0] === 'event') {
		event = event_split[1];
		if(props.concert_tour) {
			concert_tour = props.concert_tour._id;
		}
	} else if(event_split[0] === 'film') {
		film = event_split[1];
	} else if(event_split[0] === 'television') {
		television = event_split[1];
	} else {
		return <></>;
	}
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='book' />
							Publications
						</Header>
					</Divider>
					<PublicationBrowseCommon 
					event={event}
					film={film}
					television={television}
					concert_tour={concert_tour}
					urlParams={false} relatedRendered={(val) => setChildRendering(val)} slim={true}/>
				</>
			}
		</>
	);
}