import React from 'react';
import {OrganisationBrowseCommon} from './OrganisationBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment, Grid, Message } from 'semantic-ui-react'

export function OrganisationBrowse(props) {
	document.title = 'Organisations | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Organisations
				</Header>
			</Segment>
			<Grid>
				<Grid.Row>
					<Message warning style={{textAlign:'center'}}>
						<Message.Header><br/>This feature is a Beta and may not be fully accurate</Message.Header>
					</Message>
				</Grid.Row>
			</Grid>
			<OrganisationBrowseCommon urlParams={true}/>
		</div>
	);
}