import React from 'react';
import {SoundtrackBrowseCommon} from './SoundtrackBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function SoundtrackBrowse(props) {
	document.title = 'Soundtrack | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Soundtrack usage
				</Header>
			</Segment>
			<SoundtrackBrowseCommon urlParams={true}/>
		</div>
	);
}