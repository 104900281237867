import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';
import _ from 'lodash'
import { Icon, Table, Dimmer, Loader, Segment, Grid, Button, Divider, Pagination, Input, Header, Dropdown } from 'semantic-ui-react'

import { ReferencesPopup } from './../subcomponents/ReferencesPopup';

export function SoundtrackBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const ref = React.useRef(null);
	const idToOpenRef = React.useRef(null);

	const [idToUse, setIdToUse] = useState(props.urlParams ? id : null);
	const [scrollToId, setScrollToId] = useState(true);
	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
		type: [],
		day: undefined,
		year: undefined
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'string',
			},
			stringifyOptions: {
				arrayFormat: 'string',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByName, setSortByName] = useState(state.sort === 'name');
	const [types, setTypes] = useState([]);
	const typeOptions = _.map(types, (type, index) => ({
		key: type['name'],
		text: type['name'] + ' (' + type['count'] + ')',
		value: type['name'],
	}));

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(key === 'type') {
				if(!(stateTmp[key].length === state[key].length &&
				stateTmp[key].every(function(this_i,i) { return this_i === state[key][i] } ) ) ) {
					stateToSet[key] = stateTmp[key];
				}
			} else {
				if(stateTmp[key] !== undefined && state[key] !== stateTmp[key] ) {
					stateToSet[key] = stateTmp[key];
				}
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);

	const search = useCallback(() => {
		let pagetmp = state.page;
		if(resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/soundtrack/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(state.type) {
			state.type.forEach(function(filter) {
				urlAttachment += '&type=' + encodeURIComponent(filter);
			});
		}
		if(props.take) {
			urlAttachment += '&take=' + encodeURIComponent(props.take);
		}

		if(props.urlParams && !idToUse) {
			navigate('/soundtrack' + url + urlAttachment);
		}

		if(idToUse && !props.slim) {
			urlAttachment += '&id=' + idToUse;
			setScrollToId(true);
			setRowsToOpen([...rowsToOpen, idToUse]);			
		} else {
			setScrollToId(false);
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.soundtracks;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if(first_tmp < 0 ) {
					first_tmp = 0;
				}
				if(first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if(last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setTypes(json.types);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}

				setIdToUse(null);
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, firstLoad, idToUse, props, rowsToOpen, navigate]);	

	const handlePaginationClick = (e, { activePage }) => { stateHelper({page: activePage}); setChangePage(true); };
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => {
		if(scrollToId && idToOpenRef && idToOpenRef.current) {
			idToOpenRef.current.scrollIntoView({behavior: 'smooth'});
		}
	});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'type' && !Array.isArray(urlState[key])) {
					urlState[key] = [urlState[key]];
				}

				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				}
				else if(key === 'type') {
					if(!(urlState[key].length === state[key].length &&
						urlState[key].every(function(this_i,i) { return this_i === state[key][i] } ) )
					) {
						stateToSet[key] = urlState[key];
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => {
		if(state.sort === 'name') {
			setSortByName(true);
			setSortByDate(false);
		} else {
			setSortByName(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if(changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim && 
					<Grid columns={6} stackable relaxed='very'>
						<Grid.Column width={3}>
							<Button.Group>
								<Button positive={sortByName} onClick={() => changeSortField('name')}>Sort by Name</Button>
								<Button.Or />
								<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
							</Button.Group>
						</Grid.Column>
						<Grid.Column width={6}>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Type'
								fluid
								multiple
								search
								selection
								options={typeOptions}
								value={state.type}
								onChange={(e, { value }) => {stateHelper({"type": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Date</Table.HeaderCell>
							<Table.HeaderCell className="view-headercell">View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No soundtracks match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(soundtrack){
							let json_obj = {};
							let organisations = [];
							let studios = [];
							let rowToOpen = false;
							let rowToOpenId = soundtrack.id;
							if(rowsToOpen.includes(soundtrack.id)) {
								rowToOpen = true;
							} 
							if(rowToOpen) {
								try {
									json_obj = JSON.parse(soundtrack.json[0]);
									for(var organisationId in json_obj['organisations']) {
										let organisation = json_obj['organisations'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['studios']) {
										let organisation = json_obj['studios'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['sponsor']) {
										let organisation = json_obj['sponsor'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['network']) {
										let organisation = json_obj['network'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/soundtrack/' + soundtrack.id;
							return(
								<Fragment key={soundtrack.id}>
									<Table.Row key={soundtrack.id} onClick={() => 
									{
										if(rowToOpen) {
											setRowsToOpen(rowsToOpen.filter(id => id !== rowToOpenId));
										} else {
											setRowsToOpen([...rowsToOpen, rowToOpenId]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{soundtrack.name}</Table.Cell>
										<Table.Cell>{soundtrack.type}</Table.Cell>
										<Table.Cell>{soundtrack.date_display}</Table.Cell>
										<Table.Cell>
											{rowToOpen && <Icon name='angle up'/>}
											{!rowToOpen && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowToOpen &&
										<Table.Row key={soundtrack.id + '_open'} ref={idToOpenRef}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Row style={{margin:'10px'}}>
														<Grid.Column width={7} floated='left'>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='info circle' />
																	Details
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	{'date_display' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Date</Table.HeaderCell>
																			<Table.Cell>{json_obj.date_display}</Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'radio') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Radio</Table.HeaderCell>
																			<Table.Cell><Link to={'/radio/' + json_obj._id}>{json_obj.name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'television') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Television</Table.HeaderCell>
																			<Table.Cell><Link to={'/television/' + json_obj._id}>{json_obj.name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'film') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Film</Table.HeaderCell>
																			<Table.Cell><Link to={'/film/' + json_obj._id}>{json_obj.title}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'merchandise') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Merchandise</Table.HeaderCell>
																			<Table.Cell><Link to={'/merchandise/' + json_obj._id}>{json_obj.name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('network' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Network</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('sponsor' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Sponsor</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																	{'type' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Type</Table.HeaderCell>
																			<Table.Cell>{json_obj.type}</Table.Cell> 
																		</Table.Row>
																	}
																	{('organisations' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Organisations</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('studios' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Studios</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																</Table.Body>
															</Table>
															{'notes' in json_obj && 
																<>
																	<h6>Notes</h6>
																	<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
																</>
															}
														</Grid.Column>
														<Grid.Column width={7} floated='right'>
														</Grid.Column>
													</Grid.Row>
													{('tracks' in json_obj && json_obj.tracks.length > 0) &&
														<Grid.Row style={{marginLeft:'20px', marginRight: '20px'}}>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='list ol' />
																	Tracks
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	<Table.Row>
																		<Table.HeaderCell width={1}>Position</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Name</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Artist</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Length</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Recording</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Take</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Variant / Excerpt</Table.HeaderCell>
																	</Table.Row>
																	{json_obj.tracks.map(function(track) {
																		let recording = '';
																		let take = '';
																		let variant = '';
																		let length = '';
																		let name = track.name;
																		if('recording' in track) {
																			for(var pos in track.recording.sessions) {
																				if(recording !== '') { recording += '\n'; }
																				let session = track.recording.sessions[pos];
																				recording += session.obj.date_display;
																			}
																			recording = <Link to={'/recording/' + track.recording._id}>{recording}</Link>;
																		} else if('radio' in track) {
																			recording = <Link to={'/radio/' + track.radio._id}>{track.radio.date_display}</Link>;
																			if('name' in track.radio) {
																				take = track.radio.name;
																			} else {
																				take = 'Broadcast';
																			}
																		} else if('film' in track) {
																			recording = <Link to={'/film/' + track.film._id}>{track.film.title}</Link>;
																			take = 'Film';
																		} else if('television' in track) {
																			recording = <Link to={'/television/' + track.television._id}>{track.television.date_display}</Link>;
																			take = 'Television';
																		} else if('event' in track) {
																			recording = <Link to={'/event/' + track.event._id}>{track.event.date_display}</Link>;
																			take = track.event.type + ' excerpt';
																		} else if('advert' in track) {
																			recording = <Link to={'/advert/' + track.advert._id}>{track.advert.title}</Link>;
																			take = 'Advert excerpt';
																		} else if('not_sinatra' in track) {
																			recording = <i>Not Sinatra</i>;
																		} else if('not_singing' in track) {
																			recording = <i>Not Singing</i>;
																		}
																		if('variant_takes' in track) {
																			let recordings = [];
																			let takes = [];
																			track.variant_takes.map(function(variant_take) {
																				if(recordings.length > 0) {
																					recordings.push(<br/>);
																				}
																				let recStr = '';
																				for(var pos in variant_take.recording.sessions) {
																					if(recStr !== '') { recStr += '\n'; }
																					let session = variant_take.recording.sessions[pos];
																					recStr += session.obj.date_display;
																				}
																				recordings.push(<Link to={'/recording/' + variant_take.recording._id}>{recStr}</Link>);

																				if(takes.length > 0) {
																					takes.push(<br/>);
																				}
																				takes.push(<Link to={'/recording/' + variant_take.recording._id}>{variant_take.name}</Link>);
																				return variant_take;
																			});
																			recording = <>{recordings}</>;
																			take = <>{takes}</>;
																		} else if('take' in track) {
																			take = <Link to={'/recording/' + track.recording._id}>{track.take.name}</Link>;
																		}
																		if('variant' in track) {
																			variant = <span>{track.variant.name}</span>;
																			if(track.variant.length) {
																				length = track.variant.length;
																			}
																			if(track.variant.notes) {
																				variant = <><span>{track.variant.name}</span><br/><i dangerouslySetInnerHTML={{__html: track.variant.notes}}></i></>;
																			}
																		} else if('radio_variant' in track) {
																			variant = <span>{track.radio_variant.name}</span>;
																			if(track.radio_variant.length) {
																				length = track.radio_variant.length;
																			}
																		} else if('film_variant' in track) {
																			variant = <span>{track.film_variant.name}</span>;
																			if(track.film_variant.length) {
																				length = track.film_variant.length;
																			}
																		} else if('television_variant' in track) {
																			variant = <span>{track.television_variant.name}</span>;
																			if(track.television_variant.length) {
																				length = track.television_variant.length;
																			}
																		} else if('event_variant' in track) {
																			variant = <span>{track.event_variant.name}</span>;
																			if(track.event_variant.length) {
																				length = track.event_variant.length;
																			}
																		} else if('full_song' in track || (track.take && (json_obj._type === 'radio' || json_obj._type === 'television' || json_obj._type === 'film') && !track['excerpt-unknown'])) {
																			variant = <span>Full track</span>;
																			if(track.take.length) {
																				length = track.take.length;
																			}
																		} else if(track['excerpt-unknown'] || json_obj._type === 'soundtrack' || json_obj._type === 'merchandise' || json_obj._type === 'advert') {
																			variant = <span>Excerpt details not documented</span>;
																		}

																		if('radio' in track && 'tracks' in track.radio) {
																			let recordingDates = '';
																			let songNames = '';
																			let takeNames = '';
																			let variantNames = '';
																			let trackPos = 0;
																			track.radio.tracks.map(function(rTrack) {
																				if('take' in rTrack && 'recording' in rTrack) {
																					trackPos++;
																					if(recordingDates !== '') { recordingDates += '\n'; }
																					recordingDates += '↳ ' + trackPos + ') ';
																					for(var pos in rTrack.recording.sessions) {
																						if(pos > 0) { recordingDates += ', '; }
																						let session = rTrack.recording.sessions[pos];
																						recordingDates += session.obj.date_display;
																					}
																					if(songNames !== '') { songNames += '\n'; }
																					songNames += '↳ ' + trackPos + ') ' + rTrack.name;
																					if(takeNames !== '') { takeNames += '\n'; }
																					takeNames += '↳ ' + trackPos + ') ' + rTrack.take.name;
																					if('variant' in rTrack) {
																						if(variantNames !== '') { variantNames += '\n'; }
																						variantNames += '↳ ' + trackPos + ') ' + rTrack.variant.name;
																					} else {
																						variantNames += '\n';
																					}
																					if('excerpt' in rTrack || 'excerpt-unknown' in rTrack) {
																						let excerpt = '';
																						if('excerpt' in rTrack) {
																							excerpt = rTrack.excerpt;
																						} else {
																							excerpt = 'Excerpt details unknown';
																						}
																						if(variantNames) {
																							variantNames += '\n' + excerpt;
																						} else {
																							variantNames = excerpt;
																						}
																					}
																				}
																				return rTrack.pos;
																			});
																			recording = <>{recording}<br/>{recordingDates}</>;
																			name = <>{name}<br/>{songNames}</>;
																			take = <>{take}<br/>{takeNames}</>;
																			variant = <>{variant}<br/>{variantNames}</>;
																		}

																		if('info_wanted' in track) {
																			recording = <span>Information wanted</span>
																		}

																		return <Table.Row key={track.position}>
																			<Table.Cell>{track.position}</Table.Cell>
																			<Table.Cell>{name}{'actual_name' in track && <><br/><i>[corrected: {track.actual_name}]</i></>}</Table.Cell> 
																			<Table.Cell>{track.artist}{'actual_artist' in track && <><br/><i>[corrected: {track.actual_artist}]</i></>}</Table.Cell> 
																			<Table.Cell>{length}</Table.Cell> 
																			<Table.Cell>{recording}</Table.Cell>
																			<Table.Cell>{take}</Table.Cell> 
																			<Table.Cell>{variant}</Table.Cell> 
																		</Table.Row>
																	})}
																</Table.Body>
															</Table>
														</Grid.Row>
													}
													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{(('references' in json_obj && json_obj.references.length > 0) || 'radio-listings' in json_obj) &&
															<ReferencesPopup references={json_obj.references} radio_listings={json_obj['radio-listings']}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} soundtrack appearances.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}
