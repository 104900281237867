import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Divider, Pagination, Input, Header } from 'semantic-ui-react'

import { ImageGallery } from './../subcomponents/ImageGallery';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';

export function SheetMusicBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const ref = React.useRef(null);
	const idToOpenRef = React.useRef(null);

	const [idToUse, setIdToUse] = useState(props.urlParams ? id : null);
	const [scrollToId, setScrollToId] = useState(true);
	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
		type: []
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'string',
			},
			stringifyOptions: {
				arrayFormat: 'string',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByTitle, setSortByTitle] = useState(state.sort === 'title');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);
	
	const search = useCallback(() => {
		let pagetmp = state.page;
		if(resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/sheet_music/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}

		if(props.song) {
			urlAttachment += '&song=' + props.song;
		}
		if(props.organisation) {
			urlAttachment += '&organisation=' + props.organisation;
		}

		if(props.urlParams && !idToUse) {
			navigate('/sheet_music' + url + urlAttachment);
		}

		if(idToUse) {
			urlAttachment += '&sheet_music=' + idToUse;
			setScrollToId(true);
			setRowsToOpen([...rowsToOpen, idToUse]);			
		} else {
			setScrollToId(false);
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.sheet_music;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if(first_tmp < 0 ) {
					first_tmp = 0;
				}
				if(first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if(last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
				setIdToUse(null);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, navigate, firstLoad, idToUse, props, rowsToOpen]);	

	const handlePaginationClick = (e, { activePage }) => { stateHelper({page: activePage}); setChangePage(true); };
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => {
		if(scrollToId && idToOpenRef && idToOpenRef.current) {
			idToOpenRef.current.scrollIntoView({behavior: 'smooth'});
		}
	});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if(changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.sort])
	useEffect(() => {
		if(state.sort === 'title') {
			setSortByTitle(true);
			setSortByDate(false);
		} else {
			setSortByTitle(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim && 
					<Grid columns={3} stackable relaxed='very' className="width100">
						<Grid.Column>
							<Button.Group>
								<Button positive={sortByTitle} onClick={() => changeSortField('title')}>Sort by Title</Button>
								<Button.Or />
								<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
							</Button.Group>
						</Grid.Column>
						<Grid.Column>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Country</Table.HeaderCell>
							<Table.HeaderCell className="view-headercell">View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No sheet music matches your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(sheet_music){
							var json_obj = {};
							var songs = [];
							var publishers = [];
							if(rowsToOpen.includes(sheet_music.id)) {
								try {
									json_obj = JSON.parse(sheet_music.json[0]);
									for(var songPos in json_obj['songs']) {
										let song = json_obj['songs'][songPos];
										songs.push(<Link to={'/song/' + song._id} key={song._id}>{song.title}</Link>);
										songs.push(<br key={'hr_' + song._id}/>);
									}
									for(var publisherPos in json_obj['publisher']) {
										let publisher = json_obj['publisher'][publisherPos];
										publishers.push(<Link to={'/organisation/' + publisher._id} key={publisher._id}>{publisher.name}</Link>);
										publishers.push(<br key={'br_' + publisher._id}/>);
									}
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/sheet_music/' + sheet_music.id;
							return(
								<Fragment key={sheet_music.id}>
									<Table.Row key={sheet_music.id} onClick={() => 
									{
										if(rowsToOpen.includes(sheet_music.id)) {
											setRowsToOpen(rowsToOpen.filter(id => id !== sheet_music.id));
										} else {
											setRowsToOpen([...rowsToOpen, sheet_music.id]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{sheet_music.title}</Table.Cell>
										<Table.Cell>{sheet_music.date_display}</Table.Cell>
										<Table.Cell>{sheet_music.country}</Table.Cell>
										<Table.Cell>
											{rowsToOpen.includes(sheet_music.id) && <Icon name='angle up'/>}
											{!rowsToOpen.includes(sheet_music.id) && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowsToOpen.includes(sheet_music.id) &&
										<Table.Row key={sheet_music.id + '_open'} ref={idToOpenRef}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Column width={7} floated='left'>
														{('images' in json_obj && json_obj.images.length > 0) &&
															<ImageGallery images={json_obj.images} type="sheet_music"/>
														}
													</Grid.Column>
													<Grid.Column width={7} floated='right'>
														<Divider horizontal inverted>
															<Header as='h4' inverted>
																<Icon name='info circle' />
																Details
															</Header>
														</Divider>
														<Table definition inverted>
															<Table.Body>
																{songs &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Songs</Table.HeaderCell>
																		<Table.Cell>{songs}</Table.Cell> 
																	</Table.Row>
																}
																{'country' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Country</Table.HeaderCell>
																		<Table.Cell>{json_obj.country}</Table.Cell> 
																	</Table.Row>
																}
																{(publishers && publishers.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Publisher</Table.HeaderCell>
																		<Table.Cell>{publishers}</Table.Cell> 
																	</Table.Row>
																}
																{'date_display' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Release Date</Table.HeaderCell>
																		<Table.Cell>{json_obj.date_display}</Table.Cell> 
																	</Table.Row>
																}
																{'isbn' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>ISBN</Table.HeaderCell>
																		<Table.Cell>{json_obj.isbn}</Table.Cell> 
																	</Table.Row>
																}
															</Table.Body>
														</Table>
														{'notes' in json_obj && 
															<>
																<h6>Notes</h6>
																<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
															</>
														}
													</Grid.Column>
													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{('references' in json_obj && json_obj.references.length > 0) &&
															<ReferencesPopup references={json_obj.references}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} sheet music.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}