import React from 'react';
import {AdvertBrowseCommon} from './AdvertBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function AdvertBrowse(props) {
	document.title = 'Adverts and Posters | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Adverts and Posters
				</Header>
			</Segment>
			<AdvertBrowseCommon urlParams={true}/>
		</div>
	);
}