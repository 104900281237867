import React, { useState } from 'react';
import { RecordingBrowseCommon } from './../browse/RecordingBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function PersonRecordings(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='file audio outline' />
							Recordings
						</Header>
					</Divider>
					<RecordingBrowseCommon slim={true} person={props.person}
						urlParams={false} relatedRendered={(val) => setChildRendering(val)}/>
				</>
			}
		</>
	);
}