import React, { useState } from 'react';
import { PublicationBrowseCommon } from './../browse/PublicationBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function OrganisationPublications(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='book' />
							Publications
						</Header>
					</Divider>
					<PublicationBrowseCommon slim={true} organisation={props.organisation}
						urlParams={false} relatedRendered={(val) => setChildRendering(val)}/>
				</>
			}
		</>
	);
}