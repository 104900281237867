import React from 'react';
import {YearByYearCommon} from './YearByYearCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment, Grid, Message } from 'semantic-ui-react'

export function YearByYear(props) {
	document.title = 'Calendar | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Calendar
				</Header>
			</Segment>
			<Grid>
				<Grid.Row>
					<Message warning style={{textAlign:'center'}}>
						<Message.Header><br/>An in-depth, multiple-year newspaper research project has been undertaken for Sinatra's career between 1935-1942. The rest of the years are not very complete yet.</Message.Header>
					</Message>
				</Grid.Row>
			</Grid>
			<YearByYearCommon urlParams={true}/>
		</div>
	);
}