import React, { useState, useEffect, useCallback } from 'react';
import { API_ADDRESS } from './../../constants.js';

import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Pagination, Header } from 'semantic-ui-react'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

export function SoundtrackBrowseWithVariant(props) {
	const navigate = useNavigate();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'string',
			},
			stringifyOptions: {
				arrayFormat: 'string',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByName, setSortByName] = useState(state.sort === 'name');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);

	const search = useCallback(() => {
		let pagetmp = state.page;
		if(resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/soundtrack/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(props.take) {
			urlAttachment += '&take=' + props.take;
		}
		if(props.variant && props.take) {
			urlAttachment += '&variant=' + props.variant;
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.soundtracks;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if(first_tmp < 0 ) {
					first_tmp = 0;
				}
				if(first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if(last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, firstLoad, props]);	


	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if(loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if(changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])
	useEffect(() => {
		if(state.sort === 'name') {
			setSortByName(true);
			setSortByDate(false);
		} else {
			setSortByName(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	const handleClick = (event, soundtrack) => {
		if (event.ctrlKey) {
			window.open(`/soundtrack/${soundtrack}`, "_blank")
		} else {
			navigate(`/soundtrack/${soundtrack}`)
		}
	}

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				<Grid columns={3} stackable relaxed='very' className="width100">
					<Grid.Column>
						<Button.Group>
							<Button positive={sortByName} onClick={() => changeSortField('name')}>Sort by Name</Button>
							<Button.Or />
							<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
						</Button.Group>
					</Grid.Column>
					<Grid.Column>
					</Grid.Column>
				</Grid>
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Date</Table.HeaderCell>
							<Table.HeaderCell>Excerpt(s)</Table.HeaderCell>
							<Table.HeaderCell>Link</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No soundtracks match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(soundtrack){
							let variantTxt = '';
							if(props.take && 'variants' in soundtrack && 'variants' in props && props.variants && props.variants.length) {
								for(var variantNum in props.variants) {
									if(soundtrack.variants.includes(props.variants[variantNum]._id)) {
										if(variantTxt !== "") {
											variantTxt += "\n";
										}
										variantTxt += props.variants[variantNum].name;
									}
								}
							}
							let json_obj = JSON.parse(soundtrack.json[0]);
							let fullSongAdded = 0;
							let excerptNotDocumentedAdded = 0;
							json_obj.tracks.map(function(track) {
								if(track.take && track.take._id === props.take) {
									if(!('variant' in track)) {
										if('full_song' in track || (track.take && (json_obj._type === 'radio' || json_obj._type === 'television' || json_obj._type === 'film') && !track['excerpt-unknown'])) {
											if(!fullSongAdded) {
												if(variantTxt !== "") {
													variantTxt += "\n";
												}
												variantTxt += "Full track";
												fullSongAdded = 1;
											}
										} else if(json_obj._type === 'soundtrack' || json_obj._type === 'merchandise' || json_obj._type === 'advert') {
											if(!excerptNotDocumentedAdded) {
												if(variantTxt !== "") {
													variantTxt += "\n";
												}
												variantTxt += "Excerpt details not documented";
												excerptNotDocumentedAdded = 1;
											}
										}
									}
								}
								return track;
							});

							return(
								<Table.Row key={soundtrack.id} onClick={(event) => handleClick(event, soundtrack.id)} className='browse-table-row'>
									<Table.Cell>{soundtrack.name}</Table.Cell>
									<Table.Cell>{soundtrack.type}</Table.Cell>
									<Table.Cell>{soundtrack.date_display}</Table.Cell>
									<Table.Cell>{variantTxt}</Table.Cell>
									<Table.Cell>
										<Link to={'/soundtrack/' + soundtrack.id}>view</Link>
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} soundtracks.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}