import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react'
import { Link } from "react-router-dom";

export function SessionPersonnel (props) {
	// var recordingsFinal = {};
	// if(props.recordings !== undefined ) {
	// 	for(var i = 0; i < props.recordings.length; i++ ) {
	// 		recordingsFinal[props.recordings[i]._id] = props.recordings[i];
	// 		recordingsFinal[props.recordings[i]._id]['display_position'] = i+1;
	// 	}
	// }
	// var noteMap = {};
	// var rows = processRolesObject(props.people, props.session, props.showRecordings, recordingsFinal, props.sessionID, props.ct, false, 0, noteMap, props.personUrl);
	var rows = props.people;
	var element = "No musician data available";
	if(rows && Object.keys(rows).length > 0 ) {
		element = 
			<Table inverted>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Role</Table.HeaderCell>
						<Table.HeaderCell>Person</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{Object.keys(rows).map(function(role, i) {
						let rowSpan = rows[role].length;
						let maxOr = 0;
						for(let i = 0; i < rows[role].length; i++) {
							if('or' in rows[role][i]) {
								maxOr = rows[role][i]['or'];
							}
						}
						if(maxOr > 0) { rowSpan+=(maxOr-1); }
						return (
							<Fragment key={'role_'+i}>
								<Table.Row>
									<Table.Cell rowSpan={rowSpan}>{role}</Table.Cell>
									<Table.Cell><Link to={'/person/' + rows[role][0]._id}>{rows[role][0].name}</Link></Table.Cell>
									{('notes' in rows[role][0] && !props.session) &&
										<Table.Cell>{rows[role][0].notes}</Table.Cell>
									}
									{'note' in rows[role][0] &&
										<Table.Cell>{rows[role][0].note}</Table.Cell>
									}
								</Table.Row>
								{rows[role].map(function(person, i) {
									if(i === 0) { return <Fragment key={'person_' + i}></Fragment>; }
									let or_div;
									if('or' in person) {
										let last_or = rows[role][i-1]['or'];
										if(last_or !== person['or']) {
											or_div = <Table.Row><Table.Cell>or</Table.Cell></Table.Row>;
										}
									}
									return <Fragment key={'person_' + i}>{or_div}<Table.Row>
										<Table.Cell><Link to={'/person/' + person._id}>{person.name}</Link></Table.Cell>
										{('notes' in person && !props.session) &&
											<Table.Cell>{person.notes}</Table.Cell>
										}
										{'note' in person &&
											<Table.Cell>{person.note}</Table.Cell>
										}
									</Table.Row></Fragment>
								})}
							</Fragment>
						)
					})}
				</Table.Body>
				<Table.Footer fullWidth>
					{('sessions' in props && props.sessions.length > 1) && 
							<Table.Row><Table.HeaderCell colSpan={3}>
								{props.sessions.map(function(session, index) {
									return <><small key={index}>{'(' + (index+1) + ') ' + props.sessions[index].type + " session (" + props.sessions[index].obj.date_display + ")"}</small><br/></>
								})}
							</Table.HeaderCell></Table.Row>
					}
					{('recordingNotes' in props && props.recordingNotes) && 
							<Table.Row><Table.HeaderCell colSpan={3}>
								Musicians featured on all tracks unless noted above<br/>
								{props.recordingNotes}
							</Table.HeaderCell></Table.Row>
					}
				</Table.Footer>
			</Table>;
	} else {
		return element;
	}

	// var element2 = '';
	// var element2Array = [];
	// var element3 = '';

	// if('sessions' in props && props.sessions.length > 1 ) {
	// 	for(var index = 0; index < props.sessions.length; index++ ) {
	// 		element2Array.push(<p key={index}>{'(' + (index+1) + ') ' + props.sessions[index].type + " session (" + props.sessions[index].obj.date_display + ")"}</p>);	
	// 	}

	// 	element2 = <div>{element2Array}</div>;
	// }

	// if(props.showRecordings ) {
	// 	for(index = 0; index < props.recordings.length; index++ ) {
	// 		var name = props.recordings[index].song.title;
	// 		if('name' in props.recordings[index] ) {
	// 			name = props.recordings[index].name;
	// 		}
	// 		element2Array.push(<p key={index}>{'(' + (index+1) + ') ' + name}</p>);	
	// 	}

	// 	element2 = <div>{element2Array}</div>;
	// }

	// if(props.multipleSessions ) {
	// 	element3 = <div><p><i>Note: Some of the recordings were recorded over multiple sessions. Only musicians who recorded at this session appear in the above list.</i></p></div>;
	// }

	// if(noteMap !== {} ) {
	// 	for(var prop in noteMap) {
	// 		if (Object.prototype.hasOwnProperty.call(noteMap, prop)) {
	// 			element2Array.push(<p key={noteMap[prop]}>{'(' + (noteMap[prop]) + ') ' + prop}</p>);
	// 		}
	// 	}
	// 	element2 = <div>{element2Array}</div>;
	// }

				// {element2}
				// {element3}


	return (
			<>
				{element}
			</>
	);
}
