import React from 'react';
import {PublicationBrowseCommon} from './PublicationBrowseCommon';
import {PAGE_TITLE_BASE} from './../../constants.js';
import { Header, Segment } from 'semantic-ui-react'

export function PublicationBrowse(props) {
	document.title = 'Publications | ' + PAGE_TITLE_BASE;

	return (
		<div>
			<Segment inverted>
				<Header inverted as='h1' style={{textAlign:'center'}}>
					Browse Publications
				</Header>
			</Segment>
			<PublicationBrowseCommon urlParams={true}/>
		</div>
	);
}