import React, { useState } from 'react';
import { AdvertBrowseCommon } from './../browse/AdvertBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function OrganisationAdverts(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='file alternate outline' />
							Adverts
						</Header>
					</Divider>
					<AdvertBrowseCommon slim={true} organisation={props.organisation}
						urlParams={false} relatedRendered={(val) => setChildRendering(val)}/>
				</>
			}
		</>
	);
}